export const getEducationSlugPath = (slug: string, parent: string | number | null) => {
  const localePath = useLocalePath()
  const statusQuery = getStatusQuery()

  if (slug !== null && parent !== null) {
    const routeObject = {
      name: 'editions-slug-index-education',
      params: { slug: parent },
      query: {...statusQuery, educationSlug: slug }
    }
    return localePath(routeObject)
  } else if (slug && parent === null) {
    const routeObject = {
      name: 'index-education-slug',
      params: { slug: slug },
      query: statusQuery
    }

    return localePath(routeObject)
  }
}
